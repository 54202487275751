import service from '@/utils/request'; // @Tags ResourceSkuStock
// @Summary 创建EquitySkuStock
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceSkuStock true "创建EquitySkuStock"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resourceSkuStock/createEquitySkuStock [post]

export var createEquitySkuStock = function createEquitySkuStock(data) {
  return service({
    url: "/resourceSkuStock/createEquitySkuStock",
    method: 'post',
    data: data
  });
}; // @Tags ResourceSkuStock
// @Summary 删除EquitySkuStock
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceSkuStock true "删除EquitySkuStock"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /resourceSkuStock/deleteEquitySkuStock [delete]

export var deleteEquitySkuStock = function deleteEquitySkuStock(data) {
  return service({
    url: "/resourceSkuStock/deleteEquitySkuStock",
    method: 'delete',
    data: data
  });
}; // @Tags ResourceSkuStock
// @Summary 删除EquitySkuStock
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquitySkuStock"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /resourceSkuStock/deleteEquitySkuStock [delete]

export var deleteEquitySkuStockByIds = function deleteEquitySkuStockByIds(data) {
  return service({
    url: "/resourceSkuStock/deleteEquitySkuStockByIds",
    method: 'delete',
    data: data
  });
}; // @Tags ResourceSkuStock
// @Summary 更新EquitySkuStock
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceSkuStock true "更新EquitySkuStock"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /resourceSkuStock/updateEquitySkuStock [put]

export var updateEquitySkuStock = function updateEquitySkuStock(data) {
  return service({
    url: "/resourceSkuStock/updateEquitySkuStock",
    method: 'put',
    data: data
  });
}; // @Tags ResourceSkuStock
// @Summary 用id查询EquitySkuStock
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ResourceSkuStock true "用id查询EquitySkuStock"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /resourceSkuStock/findEquitySkuStock [get]

export var findEquitySkuStock = function findEquitySkuStock(params) {
  return service({
    url: "/resourceSkuStock/findEquitySkuStock",
    method: 'get',
    params: params
  });
}; // @Tags ResourceSkuStock
// @Summary 分页获取EquitySkuStock列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquitySkuStock列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /resourceSkuStock/getEquitySkuStockList [get]

export var getEquitySkuStockList = function getEquitySkuStockList(params) {
  return service({
    url: "/resourceSkuStock/getEquitySkuStockList",
    method: 'get',
    params: params
  });
};
export var getStockList = function getStockList(params) {
  return service({
    url: "/resourceSkuStock/list",
    method: 'get',
    params: params
  });
};
export var getStockExportRecords = function getStockExportRecords(params) {
  return service({
    url: "/resourceSkuStock/exportRecords",
    method: 'get',
    params: params
  });
};
export var getStockAvailableCount = function getStockAvailableCount(params) {
  return service({
    url: "/resourceSkuStock/getAvailableCount",
    method: 'get',
    params: params
  });
};
export var stockExport = function stockExport(data) {
  return service({
    url: "/resourceSkuStock/export",
    method: 'post',
    data: data
  });
};
export var stockRetryExport = function stockRetryExport(params) {
  return service({
    url: "/resourceSkuStock/retryExport",
    method: 'get',
    params: params
  });
};